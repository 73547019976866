/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const RENDERED_VIEW_ADD_LAYER_RPC_ID = 'rendered_view.addLayer';
export const RENDERED_VIEW_REMOVE_LAYER_RPC_ID = 'rendered_view.removeLayer';
export const PROJECTION_PARAMETERS_RPC_ID = 'SharedProjectionParameters';
export const PROJECTION_PARAMETERS_CHANGED_RPC_METHOD_ID = 'SharedProjectionParameters.changed';
